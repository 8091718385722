export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBRdEi6lofj6phkclJ5hA8utGGNtHGhMW4",
    authDomain: "sow-tp1.firebaseapp.com",
    projectId: "sow-tp1",
    storageBucket: "sow-tp1.appspot.com",
    messagingSenderId: "59631347288",
    appId: "1:59631347288:web:c4aa54efe16eed7a84cc2d",
    measurementId: "G-DKE0RRSL40"
  },
  user_email: 'alluser@sowtp1.org'
};
