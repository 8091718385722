import { AuthGuard } from './provider/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity-edit',
    loadChildren: () =>
      import('./activity-edit/activity-edit.module').then(
        (m) => m.ActivityEditPageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity-edit/:orgYear',
    loadChildren: () =>
      import('./activity-edit/activity-edit.module').then(
        (m) => m.ActivityEditPageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity-one/:activityID/:itemID',
    loadChildren: () =>
      import('./activity-one/activity-one.module').then(
        (m) => m.ActivityOnePageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity-item-edit/:activityID/:orgYear',
    loadChildren: () =>
      import('./activity-item-edit/activity-item-edit.module').then(
        (m) => m.ActivityItemEditPageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'family-detail/:f_id',
    loadChildren: () => import('./family/family-detail/family-detail.module').then(m => m.FamilyDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'family-member-edit/:f_id/:m_id/:m_amount/:f_name',
    loadChildren: () => import('./family/family-member-edit/family-member-edit.module').then(m => m.FamilyMemberEditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'teams-member-edit/:pid/:tid/:m_id',
    loadChildren: () => import('./teams/teams-member-edit/teams-member-edit.module').then(m => m.TeamsMemberEditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '**',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
