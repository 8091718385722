import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth }  from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private afAuth: AngularFireAuth, private router: Router) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      this.afAuth.user.subscribe(user => {
        // alert(JSON.stringify(user))
        // this.auth.user.subscribe(res => {
        //     console.log(res);
        // });
        // console.log(user);
        if (user) {
          resolve(true);
        } else {
          // console.log('尚未登入');
          this.router.navigateByUrl('/login', {
            replaceUrl: true
          });
          resolve(false);
        }
      });
    });
  }
}
